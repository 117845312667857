import "./orangeTextBox.css";
import { WideTextCard } from "../../../Utils";
import { ButtonKnowMore } from "../../../Utils/Buttons";

const cardsData = [
  {
    title: "Vision",
    text: "To be the most admired and trusted corporate solutions provider.",
  },
  {
    title: "Mission",
    text: "To provide time bound quality staffing, recruitment and training services to our clients & candidates..",
  },
];

const OrangeTextBox = ({ title }) => {
  return (
    <>
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="card-holder company-text-box ">
          <p className="p-text comp-text ">
            Orion Corporate Alliance Pvt. Ltd strives to provide efficient,
            holistic solutions in human capital to corporate powerhouses for
            their business transformation journeys. We provide end-to-end
            recruitment & flexi-staffing support to corporates for diverse skill
            sets across IT/ITeS, logistics, manufacturing among others sectors.
          </p>
          <p className="p-text comp-text ">
            Our rigorous multi-step process for recruitment, placement and long
            term support ensure that corporate houses get the best talent at all
            times. Our customized training programs for staffing associates
            ensure continuous higher productivity and sustained growth.
          </p>

          <p className="p-text comp-text ">
            We welcome you to experience and partner with Orion Corporate
            Alliance Pvt. Ltd - your end-to-end partner for your human capital
            growth.
          </p>
        </div>
        <div className="know-more-a" >
          <a
            target="_blank"
            rel="noreferrer"
            style={{textDecoration:'none', margin: 'auto', marginTop: '1.5rem'}}
            href="http://www.orioncorporate.com/"
          >
            <ButtonKnowMore text={"Know More"} />
          </a>
        </div>
      </div>
      <div className="card-holder cont-sub-com-prof">
        {cardsData.map((item) => {
          return <WideTextCard title={item.title} text={item.text} />;
        })}
      </div>
    </>
  );
};

export default OrangeTextBox;
