import "./clientsTestimonials.css";
import TestimonialCard from "../../../Utils/Cards/Testimonial Card/TestimonialCard";

const ClientTestimonials = ({title, data}) => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
      <div className="card-holder">
        {data.map((item) => {
          return (
            <TestimonialCard
              image={item.image}
              name={item.name}
              title={item.title}
              company={item.company}
              description={item.description}
              start={item.star}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClientTestimonials;
