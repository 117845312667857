import "./gallery.css";

const Gallery = ({data}) => {
  return (
    <div className="section-container">
      {/* <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div> */}
      <div className="card-holder all-img-cont ">
        <div className="key-sec-images-cont">
          <div className="img-sub-cont-sub" >
            <img
              className="small-img"
              src={data[0].image1}
              alt=""
            />
            <img
              className="small-img"
              src={data[1].image2}
              alt=""
            />
          </div>
          <img
            className="large-img"
            src={data[2].image3}
            alt=""
          />
        </div>
        <div className="key-sec-images-cont">
          <img
            className="large-img"
            src={data[3].image4}
            alt=""
          />
          <div className='img-sub-cont-sub' >
            <img
              className="small-img"
              src={data[4].image5}
              alt=""
            />
            <img
              className="small-img"
              src={data[5].image6}
              alt=""
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Gallery;
