
import { AwardCarousel } from '../../Components';

const Awards = () => {
  return (
    <div className="one-view">
      <div className="page-title">
        <h1>Awards and Recognition</h1>{" "}
      </div>
      <AwardCarousel/>
    </div>
  );
};

export default Awards;

