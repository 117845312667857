import './awards.css';
import AwardCarousel from '../../Award Carousel/Award Carousel';

const Awards = ({title, dataWeb, dataMob}) => {
    return(
        <>
      <div className="section-container bg-apna-blue">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title cl-white">{title}</h3>
        </div>
        <div className="card-holder" >
         <AwardCarousel  dataMob={dataMob} dataWeb={dataWeb}/>
        </div>
      </div>
    </>
    )
}

export default Awards