import './contactUs.css';
import AddressCard from '../../../Utils/Cards/Address Card/Address Card';

const dataAddresses = [
    {
        officeTitle: 'Corporate Office',
        addressL1: 'PS Aviator, Unit No. 508, 5th Floor, Atghora, Chinar Park, Rajarhat Road,',
        addressL2: 'Kolkata- 700136',
        mapThing: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.8086375131825!2d88.44100101453735!3d22.62362008515659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fd27b4b15a5%3A0xa4d95687dc6e52aa!2sPS+Aviator!5e0!3m2!1sen!2sin!4v1519642147119"
    },
    {
        officeTitle: 'Regional Office',
        addressL1: '869, 2nd Floor, 80 Feet Road, 8th Cross, 8th Block, Koramangala,',
        addressL2: 'Bangalore- 560095',
        mapThing: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.4766544921927!2d77.6188880143749!3d12.9413224908757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf8273465bc43877b!2sKoramangala+Police+Station!5e0!3m2!1sen!2sin!4v1526448366041"
    },
    
    {
        officeTitle: 'Regional Office',
        addressL1: 'Connaught place, New Delhi, Mandir Marg, Beside Birla Mandir, Near Golmarket,',
        addressL2: 'Delhi- 110001',
        mapThing: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.903085436358!2d77.19680761468022!3d28.63266658241774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcbaaa7edb15%3A0x2d2dc76b52aa7f6e!2sSri+Laxmi+Narayan+Mandir!5e0!3m2!1sen!2sin!4v1526449614643"
    },
    {
        officeTitle: 'Regional Office',
        addressL1: 'Plot No 39, 3rd floor, Bapu Bagh Colony Prendergast Road',
        addressL2: 'Secunderabad',
        mapThing: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.4105510542895!2d78.48090611443969!3d17.44005328804709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a0f74ee4d47%3A0x680bc1349a587d76!2sPG+Road%2C+Hyderabad%2C+Telangana+500003!5e0!3m2!1sen!2sin!4v1526448586482"
    },
]

const ContactUs = () => {
    return (
        <div className="one-view" >
            <h1 className="page-title" >Contact us</h1>
            <div>
                {
                    dataAddresses.map(item => {
                        return (
                            <AddressCard officeTitle={item.officeTitle} addressL1={item.addressL1} addressL2={item.addressL2} mapThing={item.mapThing} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ContactUs