import "./noTextAwardCard.css";

export const NoTextAwardCard = ({ name, post, image, text, width = '21rem', leadership, height, team }) => {
  return (
    <div className="notext-awards-card" style={{width:width, height:height}} >
      <div className={team ? 'notext-award-img-team' : "notext-award-img"} >
      <img className={team ? 'notext-award-img-self' : null} src={image} alt="" />
      </div>

      <div className="notext-name-post" >
        <h5 className="notext-name-post-h5" >{name}</h5>
        <p className="notext-name-post-p" >{post}</p>
      </div>

      <p className="notext-awards-card-desc">{text}</p>
    </div>
  );
};

