
import './App.css';
import {  useState, useEffect } from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {NavBar, MobileNavbar, Footer} from './Components/index';
import {Home} from './Pages'
import {CompanyProfile, Leadership, Awards} from './Pages/About Us Pages';
import {ContactUs, Services} from './Pages/Other Pages';



function App() {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const {pathname} = useLocation()

  function handleMobileMenuActive() {
    setMobileMenuActive(!mobileMenuActive);
  }

  
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    scrollToTop()
  }, [pathname])
  return (
    <div className="App">
      <NavBar
        mobileMenuActive={mobileMenuActive}
        handleMobileMenuActive={handleMobileMenuActive}
      />
      <MobileNavbar
        mobileMenuActive={mobileMenuActive}
        handleMobileMenuActive={handleMobileMenuActive}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us/company-profile" element={<CompanyProfile />} />
        <Route path="/about-us/leadership" element={<Leadership />} />
        <Route path="/about-us/awards-and-recognition" element={<Awards />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
