import "./clients.css";

const homePageLogos = [
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/download_copy_j2bejd.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/2560px-Godrej_Logo.svg_gsagw5.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/Flipkart-logo_uospr4.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872279/lms-app/corporate%20alliance%20website/clients%20logos/logo_d3r5br.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872279/lms-app/corporate%20alliance%20website/clients%20logos/ZiGccYPodVnpqgqZASPo4U-1200-80_copy_a6um1t.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872279/lms-app/corporate%20alliance%20website/clients%20logos/Mahindra_q7trna.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/2560px-Accenture.svg_bsmr6q.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/800px-TATA_Consultancy_Services_Logo_blue.svg_2x_djxcfi.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/2020-10-09_ko6a6f.png",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1638872278/lms-app/corporate%20alliance%20website/clients%20logos/1280px-Infosys_logo.svg_bnbauf.png",
  },
];

const Clients = ({ csr, logos, title }) => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>

      <div className="card-holder">
        {homePageLogos.map((logo) => {
          return (
            <div className="sub-cont">
              <img className="item" src={logo.link} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Clients;
