import "./awardCarousel.css";
import { Carousel } from "react-responsive-carousel";
// eslint-disable-next-line no-unused-vars
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

const AwardCarousel = ({ dataWeb, dataMob }) => {
console.log(window.innerWidth)

  return (
    <Carousel autoPlay infiniteLoop={true} renderThumbs={() => null} styles>
      {
        window.innerWidth < 400 ? (
          dataWeb.map((item) => {
            return (
              <div className="car-img-cont" >
                <img
                className="car-img"
                  src={item.image}
                  alt="Resource Error"
                />                
              </div>
            );
          })
        ) : (
          dataWeb.map((item) => {
            return (
              <div className="car-img-cont" >
                <img
                className="car-img"
                  src={item.image}
                  alt="Resource Error"
                />
                <img
                className="car-img"
                  src={item.image2}
                  alt="Resource Error"
                />
                
              </div>
            );
          })
        )
      }
    </Carousel>
  );
};

export default AwardCarousel;
