// import { ButtonKnowMore } from "../../buttons";
import "./focusAreaCard.css";

const FocusAreaCard = ({
  title,
  image,
  description,
  wlink,
  height,
  name,
  leaders,
  textArray
}) => {
  return (
    <div className="card-landscape focus-area-card bg-white" style={{ height: height }}>
      <div className="focus-area-sub-cont1">
        <img className="focus-area-img" src={image} alt="" />
      </div>
      <div className="focus-area-sub-cont2">
        <div className={leaders ? 'fac-sd1': "fac-sd1 width60p"}>
          {
            leaders ? (
              <div style={{marginBottom:'0.7rem'}} >
                <h4 className="card-sub-title"  >{name}</h4>
              <h5 className="card-sub-title-sub">{title}</h5>
              </div>
            ) : (
              <h4 className="card-sub-title">{title}</h4>
            )
          }
          {
            !textArray ? (<p className="p-text">{description}</p>) : (
              <ul>
                {
                  textArray.map(item => {
                    return(
                      <li className="p-text" style={{margin:'0.7rem 0'}} >{item}</li>  
                    )
                  })
                }
              </ul>
            )
          }
        </div>

        {/* {!leaders && wlink && (
          <div className="fac-sd2">
            <ButtonKnowMore />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default FocusAreaCard;
