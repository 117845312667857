import './servicesCard.css';

const ServicesCard = ({ item, index }) => {
    return (

        <div className={`h-card ${item.bgColor === '#FA8128' ? 'cl-white' : ''}`} id={item.id} style={{ backgroundColor: `${item.bgColor}` }}  >
            <div className="h-card-img-cont" >
                    <img src={item.image} className="h-card-img" alt="not avail rn" />
                </div>
            <div className='h-card-sub'>
                
                <h2 className="h-card-title" >{item.title}</h2>
                <p className="h-card-desc" >{item.description}</p>

                <div className='h-card-sub-cont' >
                    <h3 className="h-card-subTitle" >{item.subTitle1}</h3>
                    {
                        item.subTitle1Data?.map(innerItem => {
                            return (
                                <p>{innerItem}</p>
                            )
                        })
                    }
                </div>

                <div className='h-card-sub-cont' >
                    <h3 className="h-card-subTitle" >{item.subTitle2}</h3>
                    {
                        item.subTitle2Data?.map(innerItem => {
                            return (
                                <p>{innerItem}</p>
                            )
                        })
                    }
                </div>
                <div className='h-card-sub-cont' >
                    <h3 className="h-card-subTitle " >{item.listTitle}</h3>
                    <ul className='h-card-list' >
                        {
                            item.listData?.map(innerItem => {
                                return (
                                    <li>{innerItem}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>

    )
};

export default ServicesCard;