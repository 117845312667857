export const our_services_data = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675542/orion-new-website/corporate_alliance/1_Recruitment_Solutions_dprjz9.jpg",
    name: "Recruitment Solutions",
    description:
      "We create custom recruitment solutions to solve modern day challenges for corporates which has given them the independence to focus on their core businesses.",
    link: "https://staffing.orionedutech.in/services/#1",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675617/orion-new-website/corporate_alliance/2_Flexi_Staffing_yxfkqi.jpg",
    name: "Flexi Staffing",
    description:
      "Flexi staffing, an innovative offering of ours, allows us to fulfill the urgent and massive demand of temp staffing for short-term assignments of corporates in record time.",
    link: "https://staffing.orionedutech.in/services/#2",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675779/orion-new-website/corporate_alliance/3_IT_Staffing_m0dycw.jpg",
    name: "IT Staffing",
    description:
      "Through our deep understanding of latest tech stacks, their practical applications and the corporate culture of our clients, we provide best-in-class long & short term IT Staffing solutions.",
    link: "https://staffing.orionedutech.in/services/#3",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675959/orion-new-website/corporate_alliance/4_Corporate_Training_dpdlm8.jpg",
    name: "Corporate Training",
    description:
      "Our deep expertise in training and skill development gives us the unique ability to provide customized training solutions at unparalleled costs across sectors to all our clients.",
    link: "https://staffing.orionedutech.in/services/#4",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640676029/orion-new-website/corporate_alliance/5_Recruitment_Process_Outsourcing_w4qnwj.jpg",
    name: "Recruitment Process Outsourcing (RPO)",
    description:
      "RPO is a unique offering of ours providing 360 degree solutions to our corporate clients looking to manage their entire permanent staffing services under a single umbrella.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640676091/orion-new-website/corporate_alliance/6_Payroll_Compliance_Outsourcing_hjdolb.jpg",
    name: "Payroll and Compliance Outsourcing",
    description:
      "With robust technology infrastructure, unparalleled compliance capabilities and proven operational model, we offer clients payroll & compliance solutions, with speed and confidentiality.",
  },
];

export const contact_data = [
  {
    name: "Corporate Office: Kolkata",
    description:
      "PS Aviator, Unit No. 508, 5th Floor, Atghora, Chinar Park, Rajarhat Road, Kolkata-700136",
    phone: "033-46051324",
    email: "info@orioncorporate.com",
  },
  {
    name: "Regional Office: Bangalore",
    description:
      "869, 2nd Floor, 80 Feet Road, 8th Cross, 8th Block, Koramangala, Bangalore -560095, Landmark: Opposite Koramangala Police station",
  },
  {
    name: "Regional Office: Delhi",
    description:
      "Connaught place, New Delhi, Mandir Marg, Beside Birla Mandir, Near Golmarket, Delhi- 110001",
  },
  {
    name: "Regional Office: Hyderabad",
    description:
      "Plot No 39, 3rd floor, Bapu Bagh Colony Prendergast Road, Secunderabad",
  },
];

export const awardsData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675926/orion-new-website/Best_Employer_Brand_Awards_u6hkad.jpg",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675927/orion-new-website/Best_Staffing_Organization_conferred_by_Asia_Pacific_HRM_Congress_1_idkkqj.jpg",
  },
];

export const testimonialsData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1638879088/lms-app/corporate%20alliance%20website/testimonials%20images/download_copy_dobipg.png",
    name: "Mrs. Moumitha Manna",
    title: "DGM - HR",
    company: "Fives Group",
    description:
      "We started working closely with Orion Edutech only sometimes back but the experience so far has been exemplary. Their acumen in closing niche profile within the given TAT especially for a core industrial engineering organization like us is remarkable. We wish them all success in years to come.",
    stars: 4,
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1638879088/lms-app/corporate%20alliance%20website/testimonials%20images/2010-big_ooyytb.png",
    name: "Mr. Sourajith Mithra",
    title: "Manager Talent Acquisition",
    company: "Tractor India PVT. Ltd.",
    description:
      "“It has been great experience working with Orion Edutech as our recruitment partner and adding value to our business. We wish them all the very best for the future.”",
    stars: 4,
  },
  // {
  //   image:
  //     "https://res.cloudinary.com/learnhat/image/upload/v1638879088/lms-app/corporate%20alliance%20website/testimonials%20images/download_tfnfde.png",
  //   name: "Ms.Nayana Rokade",
  //   title: "Sr. Manager–HR",
  //   company: "Tata BlueScope Steel Ltd.",
  //   description:
  //     "“Orion Edutech has been our primary recruitment partner for our Human Capital solutions over the past number of years. Where we have found them ahead of the rest is their genuine strive to deliver the right candidate for our specific needs, they remain patient and understanding throughout the process.”",
  //   stars: 4,
  // },
  // {
  //   image:
  //     "https://res.cloudinary.com/learnhat/image/upload/v1638879088/lms-app/corporate%20alliance%20website/testimonials%20images/EiYNdh4__400x400_wtlgn4.png",
  //   name: "Mrs.Pritha Dutta",
  //   title: "Team Hr",
  //   company: "Magma Fincorp Ltd.",
  //   description:
  //     "“We are working with Orion Edutech since Juy15. Get valid resumes and response TAT is appreciable. Wish you all the very best for future.",
  //   stars: 4,
  // },
];

export const galleryData = [
  {
    image1:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675980/orion-new-website/staffing_gallery/WhatsApp_Image_2021-12-16_at_10.32.49_copy_h3fq5v.png",
  },
  {
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675981/orion-new-website/staffing_gallery/WhatsApp_Image_2021-12-16_at_10.32.53_1_copy_nbgqhm.png",
  },
  {
    image3:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675981/orion-new-website/staffing_gallery/WhatsApp_Image_2021-12-16_at_10.32.52_copy_x2wkjx.png",
  },
  {
    image4:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675984/orion-new-website/staffing_gallery/WhatsApp_Image_2021-12-16_at_10.32.51_copy_gexcab.png",
  },
  {
    image5:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675980/orion-new-website/staffing_gallery/WhatsApp_Image_2021-12-16_at_10.32.50_1_copy_r4pguf.png",
  },
  {
    image6:
      "https://res.cloudinary.com/learnhat/image/upload/v1640675979/orion-new-website/staffing_gallery/WhatsApp_Image_2021-12-16_at_10.32.53_copy_cwcret.png",
  },
];

export const awardCarouselImagesWeb = [
  {
    id: "5",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363200/orion-new-website/corporate_alience_awards/staffing_awards/Flipkart_Award_-_Outstanding_Best_Partner_hwcgrl.jpg",
    id2: "6",
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363267/orion-new-website/corporate_alience_awards/staffing_awards/Flipkart_Award_-_Outstanding_best_Partner_1_jz6j7d.jpg",
  },

  {
    id: "3",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363116/orion-new-website/corporate_alience_awards/staffing_awards/ISF_Award_on_completion_of_a_decade_of_transformation_of_the_Staffing_Industry_u2bwq7.jpg",
    id2: "4",
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363168/orion-new-website/corporate_alience_awards/staffing_awards/ISF_Member_Felicitation_Award_for_Driving_Formalisation_in_the_Staffing_Industry_ez2752.jpg",
  },

  {
    id: "7",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363311/orion-new-website/corporate_alience_awards/staffing_awards/Flipkart_Award_-_Best_Partner_Rank_1_-_East_j4ieno.jpg",
    id2: "8",
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363348/orion-new-website/corporate_alience_awards/staffing_awards/Final_FK_Award_ji8zep.jpg",
  },
  {
    id: "9",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363398/orion-new-website/corporate_alience_awards/staffing_awards/Best_Staffing_Organization_conferred_by_Asia_Pacific_HRM_Congress_2_vxf41m.jpg",
    id2: "10",
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363436/orion-new-website/corporate_alience_awards/staffing_awards/Best_Staffing_Organization_conferred_by_Asia_Pacific_HRM_Congress_1_rqksyl.jpg",
  },
  {
    id: "1",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363035/orion-new-website/corporate_alience_awards/staffing_awards/Recruitment_Excellence_-_Times_Job_1_2_1_at4fml.jpg",
    id2: "2",
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363073/orion-new-website/corporate_alience_awards/staffing_awards/Recruitment_Excellence_-_Times_Job_1_1_gduwig.jpg",
  },
  {
    id: "11",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363475/orion-new-website/corporate_alience_awards/staffing_awards/Best_Employer_Brand_Awards_amegzz.jpg",
    id2: "4",
    image2:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363168/orion-new-website/corporate_alience_awards/staffing_awards/ISF_Member_Felicitation_Award_for_Driving_Formalisation_in_the_Staffing_Industry_ez2752.jpg",
  },
];

export const awardCarouselImagesMob = [
  {
    id: "1",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363035/orion-new-website/corporate_alience_awards/staffing_awards/Recruitment_Excellence_-_Times_Job_1_2_1_at4fml.jpg",
  },

  {
    id: "2",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363073/orion-new-website/corporate_alience_awards/staffing_awards/Recruitment_Excellence_-_Times_Job_1_1_gduwig.jpg",
  },
  {
    id: "3",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363116/orion-new-website/corporate_alience_awards/staffing_awards/ISF_Award_on_completion_of_a_decade_of_transformation_of_the_Staffing_Industry_u2bwq7.jpg",
  },

  {
    id: "4",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363168/orion-new-website/corporate_alience_awards/staffing_awards/ISF_Member_Felicitation_Award_for_Driving_Formalisation_in_the_Staffing_Industry_ez2752.jpg",
  },

  {
    id: "5",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363200/orion-new-website/corporate_alience_awards/staffing_awards/Flipkart_Award_-_Outstanding_Best_Partner_hwcgrl.jpg",
  },

  {
    id: "6",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363267/orion-new-website/corporate_alience_awards/staffing_awards/Flipkart_Award_-_Outstanding_best_Partner_1_jz6j7d.jpg",
  },
  {
    id: "7",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363311/orion-new-website/corporate_alience_awards/staffing_awards/Flipkart_Award_-_Best_Partner_Rank_1_-_East_j4ieno.jpg",
  },
  {
    id: "8",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363348/orion-new-website/corporate_alience_awards/staffing_awards/Final_FK_Award_ji8zep.jpg",
  },
  {
    id: "9",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363398/orion-new-website/corporate_alience_awards/staffing_awards/Best_Staffing_Organization_conferred_by_Asia_Pacific_HRM_Congress_2_vxf41m.jpg",
  },
  {
    id: "10",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363436/orion-new-website/corporate_alience_awards/staffing_awards/Best_Staffing_Organization_conferred_by_Asia_Pacific_HRM_Congress_1_rqksyl.jpg",
  },
  {
    id: "11",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641363475/orion-new-website/corporate_alience_awards/staffing_awards/Best_Employer_Brand_Awards_amegzz.jpg",
  },
];
