export const dataServices = [
    {
        bgColor: 'whitesmoke',
        id: 1,
        image: 'https://res.cloudinary.com/learnhat/image/upload/v1639546130/lms-app/corporate%20alliance%20website/our%20services%20banners/1_Recruitment_Solutions_ecwrtq.jpg',
        title: 'Recruitment Solutions',
        description: 'Orion Corporate Alliance has made its mark in corporate recruitment. With business scenarios turning global extensively, it has brought with itself challenges in human resource recruitment and management. It has been our top-most priority to solve these specific challenges and custom create recruitment solution in line with the time. This has given our corporate partners the independence and resources to focus on their core businesses..',
        subTitle1: 'Our Expertise:',
        subTitle1Data: ['Experienced sourcing team',
            'Time bound recruitment solution',
            'On-boarding support',
            'Focused screening on requisite parameters',
            'Extensive database for experienced resources']
    },
    {
        bgColor: '#FA8128',
        id: 2,
        image: 'https://res.cloudinary.com/learnhat/image/upload/v1639546130/lms-app/corporate%20alliance%20website/our%20services%20banners/2_Flexi_Staffing_ftbqkv.jpg',
        title: 'Flexi Staffing',
        description: 'Flexi staffing is one of the most innovative offerings of Orion Corporate Alliance keeping in mind the changing needs and demands of workforce requirements of corporate partners. Currently, corporates have a massive, urgent demand for temp staffing for short termed assignments and we are at the forefront of fulfilling this need.',
        subTitle1: 'Dedicated Services to Clients:',
        subTitle1Data: ['Provision of manpower within 48 working hours',
            'Proper implementation of minimum wages in different states',
            'Single point of contact',
            'Access to Orion portal for easy management',
            'Registrations & documentations as per prescribed labour Laws',
            'Monthly meeting with associates on payroll to solve their issues on-spot',
            'Timely salary disbursement',
            'Responsible for statutory compliances like PF, ESIC, Mediclaim, Gratuity , Maternity benefits, Statutory bonus, Professional Tax, etc'],
        subTitle2: 'Services to Associates:',
        subTitle2Data: ['Appropriate joining formalities',
            'Appointment letter and offer letter rollout',
            'Online pay slip and online salary disbursement',
            'Document digitization',
            'E –Pehchan cards for all employees',
            'PF solution with sharing of UAN ',
            'Zero balance bank account',
            'Dedicated phone number for grievance redressal',
            'Insurance coverage & assistance on emergency',
            'Monthly meeting for implementing coherence and reliability'],
    },
    {
        bgColor: 'whitesmoke',
        id: 3,
        image: 'https://res.cloudinary.com/learnhat/image/upload/v1639546129/lms-app/corporate%20alliance%20website/our%20services%20banners/3_IT_Staffing_i7i90g.jpg',
        title: 'IT Staffing',
        description: "Understanding technology stacks and their practical applications are critical in IT recruitment which is a key strength of Orion Corporate Alliance allowing us to find the most relevant matches to jobs offered by our IT clients. We also take particular care in matching recruits who are suited to the company culture of our clients and also ensure there is sync between the recruit’s career goals and the clients’ staffing needs. Finding skilled IT professionals, even for short duration projects, has never been easier.",
        subTitle1: 'Middle & Senior Management Recruitments for Niche IT Skills',
        subTitle1Data: [
            'Extensive database of technical resources',
            'Technically qualified sourcing team for quality sourcing and validation',
            'Low turnaround time',
            'Support towards offer negotiations and onboarding'
        ],
        subTitle2: 'Immediate Availability of Resources across Diverse Skill Sets',
        subTitle2Data: [
            'Availability of resources from existing clients',
            'Availability of resources for 6 months and above',
            'Internal IT team for technical validation',
            'PAN India presence for easy on boarding and other statutory process',
        ],
        listTitle: '​​IT & Technology Skills We Cater to:',
        listData: [
            'Duck creek', 'Palo Alto', 'SAP Fico', 'IBM Message Broker', 'Java Volantee', 'TPF Redhat', 'Linux', 'Sterling OMS', 'Java/J2EE', 'Android Development', 'Microstrategy Architect', 'MAC Development', 'Pega Development', 'Automation Testing', 'LTE Testing', 'MSBI Development', 'DotNET', 'Front-end Development', 'Cognos BI Development', 'Manhattan WMOS/WMS Testing', 'Bigdata'
        ]
    },
    {
        bgColor: '#FA8128',
        id: 4,
        image: 'https://res.cloudinary.com/learnhat/image/upload/v1639546129/lms-app/corporate%20alliance%20website/our%20services%20banners/4_Corporate_Training_idlltz.jpg',
        title: 'Corporate Training',
        description: "Corporate, on an annual basis, spend extensively on equipping employees with the latest skill sets across key domains. Orion Edutech with its extensive training capability and nation-wide presence is India’s largest training and skill development provider which gives us the unique capability to provide our corporate clients with customized training programs for their employees across domains and sectors. By outsourcing employee training to Orion, clients not just reduce costs extensively but also receive quality training towards facilitating better employee output",
        listTitle: 'Customized Training Programs:',
        listData: [
            'Leadership Skills', 'Effective Business Communication', 'Delegation Skill', 'Negotiation Skills', 'Client Interaction', 'Stress Management', 'Microsoft Excel (Basic & Advanced)', 'Microsoft Powerpoint', 'Business Etiquette', 'Leading with Passion', 'Time Management', 'Effective Presentation', 'Working Together in Teams', 'Succession Planning', 'Leading Teams Effectively', 'The Sales Process', 'Taxation', 'Mentoring & Coaching', 'Work Life balance', 'Business, Email Etiquette & Grooming', 'Customer Service', 'Goal Setting', 'Motivation', 'Finance for Non Finance Employees', 'Change Management', 'Cross Culture Training Programs', 'Sales Planning', 'Telecalling Sales Skill', 'Rapport Building', 'Confidence Building', 'Supply Chain Management & Marketing', 'Managerial Effectiveness', 'Personality Development'
        ]
    },
    {
        bgColor: 'whitesmoke',
        id: 5,
        image: 'https://res.cloudinary.com/learnhat/image/upload/v1639546129/lms-app/corporate%20alliance%20website/our%20services%20banners/5_Recruitment_Process_Outsourcing_ihy6ex.jpg',
        title: 'Recruitment process outsourcing',
        description: 'A unique proposition of Orion Corporate Alliance is Recruitment Process Outsourcing (RPO). With our innovative RPO model, we cater to the 360 degrees staffing needs of corporate houses, particularly those looking to have their entire permanent staffing services under a single umbrella.',
        subTitle1: 'Our Approach Provides:',
        subTitle1Data: ['360 degree recruitment support',
            'Specific sourcing requirements that are typically more challenging to scout and process from regular staffing providers',
            'Finding and managing talent to meet specified and customized business needs']
    },
    {
        bgColor: '#FA8128',
        id: 6,
        image: 'https://res.cloudinary.com/learnhat/image/upload/v1639546129/lms-app/corporate%20alliance%20website/our%20services%20banners/6_Payroll_Compliance_ud3muj.jpg',
        title: 'Payroll and Compliance Outsourcing',
        description: 'Orion Corporate Alliance with its robust existing technology infrastructure, unparalleled statutory compliance team, and proven operational model, offers our corporate clients complete error free payroll & compliance solutions and services, with assured speed of services and confidentiality of employees’ data management.',
    },
]