import "./home.css";
import {
  Clients,
  OrangeTextBox,
  OurServices,
  ClientTestimonials,
  Contact,
  Awards,
} from "../../Components/Home/index";
import {Gallery} from '../../Components/General'
import { our_services_data, contact_data, testimonialsData, galleryData, awardCarouselImagesMob, awardCarouselImagesWeb } from "./data";

const Home = () => {
  return (
    <>
      <div className="home home-data-cont bg-home">
        <div className="home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-2">
              Staffing India, Creating Prosperity
            </h1>
          </div>
        </div>
      </div>
      <OrangeTextBox title={"Company Profile"} />
      <OurServices title={"Our Services"} data={our_services_data} />
      <Clients title={"Our Clients & Partners"} />
      <ClientTestimonials title={'What Clients Say?'} data={testimonialsData} />
      <Awards title={"Awards and Recognition"} dataMob={awardCarouselImagesMob} dataWeb={awardCarouselImagesWeb} />
      <Gallery data={galleryData}/>
      <Contact title={'Contact'} data={contact_data} />
    </>
  );
};

export default Home;
