import { NoTextAwardCard } from "../../Utils/Cards/No Text Award Card/NoTextAwardCard";
const dataLeaders = [
    {
        id: 'a',
        name: 'Sanjeev Kothari',
        post: 'Co-founder & Director',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639123859/lms-app/new-lms-website/image_2_w6pdqm.png",
    },
    {
        id: 'b',
        name: 'Manish Agarwal',
        post: 'Co-founder & Director',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639123205/lms-app/new-lms-website/Manish_Agarwal_djzo8n.jpg",
    },
    {
        id: 'c',
        name: 'Somnath Banerjee',
        post: 'Director',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639139996/lms-app/new-lms-website/5fc9d5c6-d48e-4420-a877-52523535d2d8_1_yiqhyc.png",
    },
];

const dataEmployees = [
    {
        id: 'a',
        name: 'Koushik Bhattacharyya',
        post: 'General Manager - Staffing',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639409043/lms-app/corporate%20alliance%20website/team%20members/KoushikBhattacharyya_2_gxaiht.jpg",
    },
    {
        id: 'b',
        name: 'Debtirtha Chakraborty',
        post: 'General Manager - Talent Acquisition',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639409046/lms-app/corporate%20alliance%20website/team%20members/DebtirthaChakraborty_mmo3zr.jpg",
    },
    {
        id: 'b',
        name: 'Puja Banerjee',
        post: 'Sr. HR. Manager',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639409050/lms-app/corporate%20alliance%20website/team%20members/PujaBanerjee_yastui.jpg",
    },
    {
        id: 'b',
        name: 'Dipambita Sarkar',
        post: 'Sr. Manager - Staffing',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639409054/lms-app/corporate%20alliance%20website/team%20members/DipambitaSarkar_suzw7v.jpg",
    },
    {
        id: 'e',
        name: 'Souvik Das',
        post: 'Regional Manager',
        image:
            "https://res.cloudinary.com/learnhat/image/upload/v1639473755/lms-app/corporate%20alliance%20website/team%20members/SOUVIKDAS_enycp7.jpg",
    },
];

const Leadership = () => {
    return (
        <>
            <div className="one-view">
                <h1 className="page-title" >Leadership </h1>
                <div className="card-holder-full">
                    {dataLeaders.map((item) => {
                        return <NoTextAwardCard name={item.name} post={item.post} image={item.image} text={item.text} height={"23rem"} />;
                    })}
                </div>
            </div>
            <div className="bg-apna-orange">
                <div className="one-view">
                    <h4 className="leadership-sub-title cl-white" >From the Directors’ Desk</h4>
                    <p className="one-view-text-box cl-white" >We are standing on the verge of an era where permanency would be an out of date phenomenon and staffing solutions, particularly flexi-staffing, would be central in the world of employment and human resources. We are an integral cog of the staffing industry which will cross 6 million jobs created by 2021 but still majorly behind behemoths such as USA & China in spite of being the youngest nation in the world with huge working age population that forms our demographic dividend. </p>
                    <p className="one-view-text-box cl-white" >At Orion Corporate Alliance, we have taken on the challenge to bridge this gap by strengthening our recruitment and staffing solutions with a pledge to create an ecosystem that provides flexibility, administrative efficiencies and flawless statutory compliance for corporate houses to focus and expand their business. We are grateful to Corporate India who have given us the opportunities to work with them and emerge as their trusted solution provider. We look forward to working and growing together. </p>
                    <p className="cl-white" style={{textAlign:'right', padding:'1rem'}} > - Sanjeev, Manish and Somnath</p>
                </div>
            </div>
            <div className="one-view">
            <h1 className="page-title" >Team</h1>
            <div className="card-holder-full">
                {dataEmployees.map((item) => {
                    return <NoTextAwardCard  name={item.name} post={item.post} image={item.image} text={item.text} width={'15rem'} height={"20rem"} team />;
                })}
            </div>
        </div>
        </>
    )
}

export default Leadership