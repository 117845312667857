import './addressCard.css'

const AddressCard = ({officeTitle, addressL1, addressL2, mapThing}) => {
    return (
        <div className="contactus-sub-cont">
            <div className="contactus-sub-cont-1 card-landscape" >
                <div className="contactus-boxs">
                    <h3>Address</h3>
                    <p>{officeTitle}:</p>
                    <p>{addressL1}</p>
                    <p> {addressL2}</p>
                </div>
                <div className="contactus-boxs" >
                    <h3>Call Us</h3>
                    <p>18002004112</p>
                </div>
                <div className="contactus-boxs" >
                    <h3>Email Us</h3>
                    <p>info@orionedutech.com</p>
                </div>
            </div>
            <div className="contactus-sub-cont-2 card-landscape" >
                {/* <img className="contactus-map" src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821__340.jpg" alt="" /> */}
                <iframe title="heloo" src={mapThing} width="100%" height="100%" frameborder="0"  allowfullscreen=""></iframe>

            </div>
        </div>
    )
}

export default AddressCard