import './aboutUs.css'

const CompanyProfile = () => {
  return (
    <div className="one-view">
      <div className="page-title" ><h1>Company Profile</h1></div>
      <p className="one-view-text-box">
      Orion Corporate Alliance (a subsidiary of Orion Edutech, an NSDC partnered & ISO 9001:2015 certified company) has been founded with the ambition to provide end-to-end holistic solutions in providing highly efficient human potential with corporate powerhouses towards the journey of business transformation.{" "}
      </p>
      <p className="one-view-text-box">
      Being India’s leading holistic staffing company, we provide end to end recruitment & flexi-staffing support to corporates for various processes and skill sets in IT/ITeS and other sectors such as logistics, manufacturing among many others. We are aware that human resources are the face of any organization and our process of selecting and placing human resources across leadership, mid-level & associate levels in generic, niche & technical skill sets through a rigorous multi-step process ensures that corporate houses get the best talent at all times. 
      </p>
      <p className="one-view-text-box">
      We are led by a dynamic group of professionals with deep expertise in the staffing sector. Through our customized training programs for the employees of corporate houses and large enterprises towards enablement of higher productivity and sustained growth, we have ensured that our clients are always focused on their growth and core competencies without having to worry about hiring better or faster.
      </p>
      <p className="one-view-text-box">
      We welcome you to experience and partner with Orion Corporate Alliance - your end-to-end partner for your human resource growth.
      </p>
      <div className="mission-vision-box" >
      <p className="one-view-text-box">
        <span className="sub-title"  >MISSION:</span>To provide time bound quality staffing, recruitment and training services to our clients & candidates. 
      </p>
      <p className="one-view-text-box">
        <span className="sub-title" >VISION:</span>To be the most admired and trusted corporate solutions provider.
      </p>
    </div>
    </div>
  );
};

export default CompanyProfile;
