import './wideTextCard.css'

const WideTextCard = ({title, text}) => {
    return(
        <div className="card-landscape wide-text-card" >
            <h5 className="card-sub-title wc-title" >{title}</h5>
            <p className="p-text" >{text}</p>
        </div>
    )
}

export default WideTextCard