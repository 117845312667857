import './services.css';
import ServicesCard from '../../../Utils/Cards/Services Card/Services Card';
import { dataServices } from './servicesData';

const Services = () => {
    return (
        // 
        <div  >
            <div className='one-view no-bottom-pd' >
            <div className="page-title" ><h1>Our Services</h1></div>
            </div>
            <div>
                {
                    dataServices.map((item, index) => {
                        return (
                            <div id={item.id} index={index} ><ServicesCard item={item} /></div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default Services