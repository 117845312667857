import "./ourServices.css";
import { FocusAreaCard } from "../../../Utils";

const OurServices = ({ data, title }) => {
  return (
    <div className="section-container bg-apna-blue">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title cl-white">{title}</h3>
      </div>
      <div className="card-holder">
        {data.map(({ image, name, title, description }) => {
          return (
            <FocusAreaCard
              title={title}
              image={image}
              description={description}
              name={name}
              height={"17rem"}
              leaders
            />
          );
        })}
      </div>
    </div>
  );
};

export default OurServices;
